.footer-top {
  background-color: var(--blue-color);
}

.footer-top > div,
.footer-middle > div {
  max-width: 1320px;
}

.footer-top-social-parent {
  background-color: rgba(255, 255, 255, 0.1);
}

.footer-top-social-parent > a {
  width: 50px;
  height: 50px;
  transition: all 0.3s;
}

.footer-top-social-parent > a:hover {
  transform: translateY(-10px);
  background-color: var(--main-color) !important;
  color: #fff !important;
}

.footer-subscribe-form-parent form {
  width: 100%;
  max-width: 400px;
}

.footer-subscribe-form-submit {
  all: unset;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  color: #fff;
  background-color: var(--main-color);
}

.footer-middle-contact > div {
  margin: 0.5rem 0;
}

.footer-middle-contact > div > div {
  width: 40px;
  height: 40px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.footer-middle-contact svg {
  color: var(--main-color) !important;
}

.footer-nav-item-parent strong::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 40px;
  height: 2px;
  background-color: var(--main-color);
}