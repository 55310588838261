.section-one-swiper-items img {
  width: 100%;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 20px !important;
  visibility: hidden;
  color: #000;
  font-weight: bold;
  background-color: #fff;
  padding: 20px;
}

.swiper:hover .swiper-button-prev::after,
.swiper:hover .swiper-button-next::after {
  visibility: visible;
}

.swiper-button-next::after {
  border-radius: 50px 0 0 50px;
}

.swiper-button-prev::after {
  border-radius: 0 50px 50px 0;
}

.swiper-button-prev:hover::after,
.swiper-button-next:hover::after {
  background-color: var(--main-color);
  color: #fff;
}

.swiper-pagination-bullet {
  background-color: hsl(0, 0%, 100%) !important;
  padding: 5px;
  opacity: 0.7;
  transition: all 0.2s;
}

.swiper-pagination-bullet-active {
  background-color: var(--main-color) !important;
  padding: 5px 8px;
  border-radius: 50px !important;
}

.section-one-banner-parent img {
  max-width: 400px;
}

.section-one-offer-items-parent {
  width: 100% !important;
  display: inline-block !important;
}

.section-one-offer-items-parent img {
  max-width: 250px;
  max-height: 250px;
}

.section-one-offer-timer-parent {
  background-color: var(--blue-color);
}

.section-one-offer-text-parent {
  background-color: var(--blue-dark-color);
}

@media screen and (min-width: 768px) {
  .section-one-offer-items-parent img {
    max-width: 150px;
    max-height: 200px;
  }
  .section-one-banners-parent {
    width: 65%;
  }
  .section-one-offer-parent {
    width: 33%;
    height: 100%;
  }
}

@media screen and (min-width: 992px) {
  .section-one-offer-items-parent img {
    margin: 1rem auto;
  }
}

@media screen and (min-width: 1200px) {
  .section-one-offer-items-parent img {
    max-width: 300px;
    height: 300px;
    max-height: unset;
  }

  .section-one-banners-parent {
    width: 65%;
  }
  .section-one-offer-parent {
    width: 33%;
    height: 100%;
  }
}

@media screen and (min-width: 1400px) {
  .section-one-offer-items-parent img {
    margin: 3rem auto;
  }

}
