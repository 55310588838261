.blog-post-item {
  transition: all 0.3s;
}

.blog-post-item:hover {
  box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 15px 0px;
}

.blog-post-item > div:nth-child(1) img {
  transition: all 0.5s;
}

.blog-post-item > div:nth-child(1):hover img {
  transform: scale(1.05);
}

.blog-post-item > div:nth-child(2) span {
  font-size: 14px;
}

.blog-post-item > div:nth-child(2) p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  white-space: pre-wrap;
}

.blog-post-item-read-btn {
  background-color: #f5f5f5;
}

.blog-post-item:hover .blog-post-item-read-btn {
  background-color: var(--main-color);
  color: #fff;
}
