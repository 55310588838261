.section-three {
  background-color: var(--blue-color);
}



.section-three .swiper-button-prev::after,
.section-three .swiper-button-next::after {
  visibility: visible;
}

