:root {
  --main-color: #ff6600;
  --blue-color: #2d416f;
  --blue-dark-color: #26375e;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "roboto", Arial, Helvetica, sans-serif;
  direction: ltr;
}
body {
  background-color: #f5f5f5;
}
.fade-in {
  position: absolute;
  animation: fadeIn 0.2s linear;
  visibility: visible;
}

p,
span,
ul,
li,
div {
  padding: 0;
  margin: 0;
}
@keyframes fadeIn {
  from {
    bottom: -60px;
    opacity: 0;
  }
  to {
    bottom: -50px;
    opacity: 1;
  }
}

.fade-out {
  position: absolute;
  animation: fadeOut 0.2s linear;
  visibility: hidden;
}

@keyframes fadeOut {
  from {
    bottom: -50px;
    opacity: 1;
  }
  to {
    bottom: -60px;
    opacity: 0;
  }
}

.nav-link {
  transition: all 0.1s;
  cursor: pointer;
}

.primary-color {
  color: var(--main-color) !important;
}

.nav-link:hover {
  color: var(--main-color);
}

.product-btns-parent button {
  background-color: #f5f5f5;
  transition: all 0.1s;
}

.product-btns-parent button:hover {
  background-color: var(--main-color);
  color: #fff;
}

.highIndex {
  z-index: 10;
}

.cursor-unset {
  cursor: auto !important;
}

.toast-add-to-cart-notif > div:first-child {
  min-width: 80px;
}

.toast-add-to-cart-notif strong {
  overflow: hidden;
  display: -webkit-inline-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;
}
.product-btn-hover {
  width: 100%;
  left: 0;
  bottom: -30px;
}

.product-item-box {
  border-radius: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.product-item-box:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.product-item-box > div:nth-child(1) {
  height: 300px;
}

.product-item-box > div:nth-child(1) img {
  width: 180px;
  height: fit-content;
  max-height: 200px;
  transition: all 0.3s;
}

.product-item-box:hover div:nth-child(1) img {
  transform: scale(1.05);
}

.product-item-box strong {
  height: 45px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@media screen and (min-width: 992px) {
  .product-btn-hover {
    visibility: hidden;
    position: absolute;
    transition: all 0.2s;
    opacity: 0;
    transform: translateY(-30px);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  .product-btn-hover::before {
    content: "";
    height: 25px;
    width: 100%;
    background-color: #fff;
    top: -10px;
    display: inline-block;
    left: 0;
    position: absolute;
    z-index: -1;
  }

  .product-item-box {
    border-radius: 1rem;
  }

  .product-item-box:hover {
    border: 0;
    z-index: 2;
  }

  .product-item-box:hover .product-btn-hover {
    visibility: visible;
    opacity: 1;
    transform: translateY(0px);
  }
}
