.on-header-menu {
  font-size: 13px;
}

.change-language-icon {
  width: 20px;
}

.change-lang-drop-down .fa-chevron-down {
  margin: 0 5px;
  font-size: 13px;
  justify-content: space-between;
}

.header-logo-parent {
  max-width: 250px;
}

.header-search {
  width: 90%;
  height: 50px;
  border: 2px solid var(--main-color);
  overflow: hidden;
}

.header-search-submit-parent {
  background-color: var(--main-color);
  height: 100%;
  vertical-align: middle;
  cursor: pointer;
  width: 25%;
  min-width: 50px;
  max-width: 75px;
}

.header-search .fa-magnifying-glass {
  color: #fff;
  display: block;
}

.header-search-input-parent {
  width: 80%;
}
.header-search-input-parent input::placeholder {
  font-size: 14px;
}

.header-search-input-parent input:focus,
.header-search-category-parent select {
  border: none;
  outline: none;
}

.header-icons-parent {
  font-size: 25px;
}

.header-shopping-cart-count-badge,
.header-wishlist-count-badge {
  font-size: 14px;
  background-color: var(--main-color);
}

nav {
  background-color: var(--blue-color);
  font-size: 15px;
}

nav > div {
  min-height: 60px;
}

.mobile-menu-humbugger {
  display: inline-flex;
  border: 1px solid #fff;
  cursor: pointer;
}

.main-desktop-menu {
  transition: all 0.5s;
}

.main-desktop-menu ul {
  height: 60px;
}
.main-desktop-menu .fa-angle-down {
  font-size: 14px;
  vertical-align: middle;
  padding: 0 5px;
}

.shop-by-category-nav {
  background-color: rgba(255, 255, 255, 0.1);
  height: 60px;
  transition: all 0.1s;
}

.shop-by-category-nav:hover {
  background-color: var(--main-color);
  color: #fff;
}

.shop-by-category-nav .fa-bars {
  font-size: 20px;
}

.shop-by-category-child {
  visibility: hidden;
  z-index: 5;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 0 0 10px 10px;
  transition: all 0.3s;
  opacity: 0;
  color: #000;
}

.shop-by-category-nav:hover .shop-by-category-child {
  visibility: visible;
  top: 60px;
  opacity: 1;
}

.shop-by-category-nav > div:first-child {
  min-width: 20px;
}

.mobile-nav-parent {
  z-index: 10;
}

.mobile-nav-parent.active {
  visibility: visible !important;
  background-color: hsl(0deg 0% 0% / 60%);
  transition: all 0.1s;
}

.mobile-nav {
  transform: translateX(-500px);
  visibility: hidden;
  height: 100vh;
  width: 100%;
  max-width: 320px;
  background-color: #fff;
  transition: all 0.2s;
}

.mobile-nav-parent.active .mobile-nav {
  transform: translateX(0);
  visibility: visible;
}

.mobile-nav-close-btn-parent {
  width: 100%;
  text-align: center;
}

.mobile-active-category {
  opacity: 1 !important;
  color: #000 !important;
  transition: unset !important;
}

.menu-list-items {
  cursor: pointer;
}

.mobile-menu-item-parent-active {
  display: block !important;
}

.tree-menu-child {
  width: 100%;
  height: 0px;
  overflow: hidden;
  transition: all 0.2s;
  opacity: 0;
}

.mobile-menu-angle-tree.active > svg {
  transition: all 0.2s;
  transform: rotate(90deg);
}

.tree-menu-child.active {
  overflow: visible;
  opacity: 1;
}

.main-desktop-menu > ul > li > div {
  top: 60px;
  left: 45%;
  transform: translateX(-45%);
  min-width: fit-content;
  width: max-content;
  transform: translateX(-50%);
  background-color: #fff;
  visibility: hidden;
  transition: all 0.2s;
  opacity: 0;
  box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
  border-radius: 0 0 10px 10px;
}

.main-desktop-menu > ul > li:hover > div {
  visibility: visible;
  opacity: 1;
}

.header-shopping-cart-parent > div {
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  min-width: 280px;
  width: 320px;
  left: -50%;
  transform: translateX(-30%);
  top: 50px;
  box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
  z-index: 5;
}

.header-shopping-cart-parent:hover > div {
  visibility: visible;
  opacity: 1;
  color: #000;
  top: 40px;
}

.shopping-cart-items-parent {
  min-height: 30px;
  max-height: 350px;
  overflow: auto;
}

.shopping-cart-item > div:first-child {
  max-width: 60px;
}

.shopping-cart-item strong {
  overflow: hidden;
  display: -webkit-inline-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  white-space: pre-wrap;
}

.shopping-cart-bottom-btns button {
  transition: all 0.2s;

}

.shopping-cart-bottom-btns button a {
  text-decoration: none;
  color: inherit;
}
.shopping-cart-bottom-btns button:nth-child(1) {
  outline: 1px solid #000;
  color: #000;
}

.shopping-cart-bottom-btns button:nth-child(2) {
  background-color: #000;
  color: #fff;
}

.shopping-cart-bottom-btns button:hover {
  background-color: var(--main-color) !important;
  color: #fff !important;
  outline: none;
}

@media screen and (min-width: 992px) {
  .mobile-nav-parent.active {
    visibility: hidden !important;
  }

  .mobile-nav-parent.active .mobile-nav {
    visibility: hidden;
    transform: translateX(-500px);
  }
}
